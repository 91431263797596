import React, { useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom'
import Menu from './menu/menu';
import Home from './home/home';
import Login from './login/login';
import { TokenRepository } from './TokenRepository';
import * as ActivityLogger from "./shared/activity-logger";
import * as Data from './login/data';

function App() {
    const tokenRepository = new TokenRepository();
    const token = tokenRepository.getToken();
    //const holdToken = tokenRepository.getHoldToken();

    const InterCompanyBilling = React.lazy(() => import('./fileExplorer/report-explorer'));
    const Morv2 = React.lazy(() => import('./fileExplorer/report-explorer'));
    const Volrate = React.lazy(() => import('./volrate/volrate'));
    const ContactUsAdmin = React.lazy(() => import('./contactUsAdmin/contact-us-admin'));    
    const APGL = React.lazy(() => import("./apglenvi/apgl"));
    const UserAdmin = React.lazy(() => import("./useradmin/user-admin"));
    const QualityIndicators = React.lazy(() => import("./qualityIndicators/qualityIndicators"));
    const RevenueWorkbook = React.lazy(() => import("./revenueworkbook/revenue-workbook"));
    const MedicareEOY = React.lazy(() => import('./datapointreport/data-point-report'));
    const OWR = React.lazy(() => import('./datapointreport/data-point-report'));
    const DataPointAdmin = React.lazy(() => import('./datapointreportAdmin/data-point-report-admin'));
    const FacilityOnboarding = React.lazy(() => import("./facilityonboarding/facility-onboarding"));
    const NSH = React.lazy(() => import("./nsh/nsh"));
    const Account = React.lazy(() => import("./account/account"));    
    const Administration = React.lazy(() => import('./administration/administration'));
    const CptSpecMap = React.lazy(() => import('./cptspecmap/cpt-spec-map'));
    const RecordLookup = React.lazy(()=> import("./recordLookup/recordLookup"));
    const SpecialtyMapping = React.lazy(() => import("./specialtyMapping/specialtyMapping"));
    const EtlJob = React.lazy(() => import("./etlJob/etlJob"));
    const UltiProMissingMappings = React.lazy(() => import("./Ultipro/utliproMissingMappings"));
    const EtlMapping = React.lazy(() => import("./etlMapping/etlMapping"));
    const PatientLookup = React.lazy(() => import("./patientLookup/PatientLookup"));
    const RawFinancialDataExport = React.lazy(() => import("./rawFinancialData/RawFinancialDataExport"));
    const tokenCheckFrequency: number = 1; // delay in minutes

    const [isAuthorizing, setIsAuthorizing] = useState(false);

    const logout = () => {
        tokenRepository.saveToken(null);
        sessionStorage.setItem('loginMessage', 'You have been logged out due to inactivity.');
        sessionStorage.removeItem('routesTaken');
        window.location.reload();        
    };

    const isRefreshTokenExpired = async (tokens) => {
        if (tokens === null)
            return true;

        console.log('isRefreshTokenExpired fetching');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL.concat('/api/login/IsRefreshTokenExpired'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Access_Token: tokens.access_Token,
                    HoldTokenKey: '',
                    Refresh_Token: tokens.refresh_Token,
                }),
            });

            if (response.status >= 200 && response.status < 300) {
                return await response.text() === 'true'; // Check if the response text is "true"
            } else {
                return true; // Treat any non-2xx status as expired
            }
        }
        catch (error) {
            // TODO: Log this on the server
            return true;
        }
    };

    const showLoginIfTokensExpired = async (tokens) => {
        isRefreshTokenExpired(tokens).then(response => {
            if (response) {
                logout();
            }
        });
    }

    // check at set intervals if token has expired
    useEffect(() => {
        const delay = 1000 * 60 * tokenCheckFrequency;

        const interval = setInterval(() => {
            const tokens = tokenRepository.getToken();
            if (tokens !== null) {                
                showLoginIfTokensExpired(tokens);
            } 
        }, delay);

        return () => clearInterval(interval);
    }, []);    

    //useEffect(() => {
    //    let timer: NodeJS.Timeout;

    //    if (isAuthorizing) {
    //        timer = setTimeout(() => {
    //            setIsAuthorizing(false);
    //            sessionStorage.setItem('loginMessage', 'Essentials did not receive a response from DuoWeb, please try logging in again.');
    //        }, 30000); // 30 seconds
    //    }

    //    return () => {
    //        if (timer) {
    //            clearTimeout(timer);
    //        }
    //    };
    //}, [isAuthorizing]);

    useEffect(() => {
        const holdToken = tokenRepository.getHoldToken();

        if (holdToken) {
            //setIsAuthorizing(true);
            const holdTokenKey = holdToken.holdTokenKey;

            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');
            const state = query.get('state');

            if (code && state) {
                setIsAuthorizing(true);
                Data.validateMfaUniversal(code, state, holdTokenKey).then((token) => {
                    tokenRepository.saveToken(token);

                    // Get the current URL without any query string parameters
                    const baseUrl = window.location.origin + window.location.pathname;

                    // Redirect to the same page without any query string parameters
                    window.location.href = baseUrl;
                    setIsAuthorizing(false);
                });
            }
            tokenRepository.clearHoldToken();
        }        
    }, []);

    if (isAuthorizing) {
        //return <div>Authorizing...</div>;
        return <div className="d-flex align-items-center mt-4">
            <div className="spinner-border" role="status"></div>
            <strong>&nbsp;&nbsp;Authorizing, waiting for a response from Duo...</strong>
        </div>;
    }

    if (!token) {
        return <Login />
    } else {
        showLoginIfTokensExpired(token);
    }

    return (
        <div className="App">
            <BrowserRouter>
                <RouteTracking />
                <Menu />
                <div id="module-root">
                    <Routes>
                        <Route
                            index
                            element={
                                <Home />
                            }
                        />
                        <Route
                            path="/ApglEnviV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <APGL id="ApglEnviV3" appRefName="ApglEnviV3" title="APGL-ENVI" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/IntercompanyBillingV3"                     
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <InterCompanyBilling id="IntercompanyBillingV3" key="IntercompanyBillingV3" appRefName="IntercompanyBillingV3" title="Intercompany Billing" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/MorV3"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <Morv2 id="MorV3" appRefName="MorV3" key="MorV3" title="Monthly Operating Reports" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/VolRateV3"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <Volrate id="VolRateV3" appRefName="VolRateV3" title="Volume Rate" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/ContactUsAdminV3"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <ContactUsAdmin id="ContactUsAdminV3" appRefName="ContactUsAdminV3" title="Contact Us Admin" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/UserAdminV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <UserAdmin id="UserAdminV3" appRefName="UserAdminV3" title="User Admin" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/QualityIndicatorsV3/*"                            
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <QualityIndicators id="QualityIndicatorsV3" appRefName="QualityIndicatorsV3" title="Quality Indicators" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/RevenueWorkbookV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <RevenueWorkbook id="RevenueWorkbookV3" appRefName="RevenueWorkbookV3" title="Revenue Workbook" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/MedicareEoyV3/*"
                            element={
                                <React.Suspense fallback={<React.Fragment>...Loading...</React.Fragment>}>
                                    <MedicareEOY id="MedicareEoyV3" key="MedicareEoyV3" appRefName="MedicareEoyV3" title="Medicare EOY" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/OperationsWeeklyReportV3/*"
                            element={
                                <React.Suspense fallback={<React.Fragment>...Loading...</React.Fragment>}>
                                    <OWR id="OWR" appRefName="OperationsWeeklyReportV3" key="OperationsWeeklyReportV3" title="Operations Weekly Report" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/DataPointAdminV3/*"
                            element={
                                <React.Suspense fallback={<React.Fragment>...Loading...</React.Fragment>}>
                                    <DataPointAdmin id="DataPointAdminV3" appRefName="DataPointAdminV3" title="Data Point Report Admin" />
                                </React.Suspense>
                            }
                        />
                         <Route
                            path="/FacilityOnboardingV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <FacilityOnboarding id="FacilityOnboardingV3" appRefName="FacilityOnboardingV3" title="Facility Onboarding" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/EncounterExpectedReimbursement/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <NSH id="EncounterExpectedReimbursement" appRefName="EncounterExpectedReimbursement" title="Encounter Expected Reimbursement" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/CptSpecialtyMappingV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <CptSpecMap id="CptSpecialtyMappingV3" appRefName="CptSpecialtyMappingV3" title="CPT Specialty Mapping" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/Account/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <Account  />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/AdministrationV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <Administration id="AdministrationV3" appRefName="AdministrationV3" title="Administration" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/RecordLookupV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <RecordLookup id="RecordLookupV3" appRefName="RecordLookupV3" title="Legacy Record Lookup" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/SpecialtyMappingV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <SpecialtyMapping id="SpecialtyMappingV3" appRefName="SpecialtyMappingV3" title="Specialty Mapping" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/ETLJobV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <EtlJob id="ETLJobV3" appRefName="ETLJobV3" title="ETL Job" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/UltiProManageMappingsV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <UltiProMissingMappings id="UltiProManageMappingsV3" appRefName="UltiProManageMappingsV3" title="Manage Missing Mappings" />
                                </React.Suspense>
                            }

                        />
                        <Route
                            path="/EtlMappingV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <EtlMapping id="ETLMappingV3" appRefName="ETLMappingV3" title="ETL Mapping" />
                                </React.Suspense>
                            }

                        />
                        <Route
                            path="/PatientLookupV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <PatientLookup id="PatientLookupV3" appRefName="PatientLookupV3" title="Patient-lookup" />
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="/RawFinancialDataV3/*"
                            element={
                                <React.Suspense fallback={<>...Loading...</>}>
                                    <RawFinancialDataExport id="RawFinancialDataV3" appRefName="RawFinancialDataV3" title="Raw Financial Data" />
                                </React.Suspense>
                            }
                        />
                    </Routes>
                </div>
            </BrowserRouter>            
            </div>
    );
}

const RouteTracking = () => {
    const location = useLocation();

    const getRouteIfNotTaken = () => {
        const route = location.pathname.split('/')[1];        
        if (!route) {
            return;
        }

        //const routesTaken = sessionStorage.getItem('routesTaken');
        //if (routesTaken) {
        //    const routes = JSON.parse(routesTaken);
        //    if (routes && routes.includes(route)) {
        //        return null;
        //    }
        //}
        return route;
    }

    //const storeRoute = (route: string) => {
    //    const routesTaken = sessionStorage.getItem('routesTaken');
    //    if (!routesTaken)
    //        sessionStorage.setItem('routesTaken', JSON.stringify([route]));
    //    else {
    //        const routes = JSON.parse(routesTaken)
    //        routes.push(route);
    //        sessionStorage.setItem('routesTaken', JSON.stringify(routes));
    //    }
    //}

    useEffect(() => {
        
        // Whenever the route changes, track the route usage by making an API call
        const route = getRouteIfNotTaken();
        if (route) {
            //storeRoute(route);
            ActivityLogger.LogUserActivity(route);
        }
    }, [location.pathname]);

    return null; // This component doesn't render anything
};

export default App;
